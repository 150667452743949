"use strict";

/* ------------------------------------------------------------
	設定
 * ------------------------------------------------------------ */
let common = {};
common.event = {};
const breakPoints = {
	p: 1024,
};
common.breakPoints = breakPoints;
let locationHash = window.location.hash,
	$windowHeight,
	$headerHeight,
	sc,
	tsc;

/* ------------------------------------------------------------
	Window Print
* ------------------------------------------------------------ */
window.onbeforeprint = function () {
	$('img').each(function () {
		$(this).removeAttr('loading')
	});
}

/* ------------------------------------------------------------
	userAgent判別
 * ------------------------------------------------------------ */
var ua = window.navigator.userAgent;
var appVer = window.navigator.appVersion;

//PC
var isIE = ua.indexOf("MSIE") != -1 || ua.indexOf("Trident") != -1;
var isIE6 = isIE && appVer.indexOf("MSIE 6") != -1;
var isIE7 = isIE && appVer.indexOf("MSIE 7.") != -1;
var isIE8 =
	(isIE && ua.indexOf("Trident/4.") != -1) || appVer.indexOf("MSIE 8.") != -1;
var isIE9 = isIE && ua.indexOf("Trident/5.") != -1;
var isIE10 = isIE && ua.indexOf("Trident/6.") != -1;
var isIE11 = ua.indexOf("Trident/7.") != -1;
var isMicrosoftEdge = ua.indexOf("Windows NT") != -1;
var isOldIE = isIE8 || isIE7 || isIE6;

var isFirefox = ua.indexOf("Firefox") != -1;
var isChrome = ua.indexOf("Chrome") != -1;
var isSafari = ua.indexOf("Safari") != -1;

//Mobile (smartphone + tablet)
var isMobileSafari = ua.match(/iPhone|iPad|iPod/i) ? true : false;
var isMobileSafariTypeT = ua.match(/ipad/i) ? true : false;
var isMobileSafariTypeS = ua.match(/iphone|ipod/i) ? true : false;
var isAndroid = ua.indexOf("Android") != -1;
var isMobileAndroidTypeT = isAndroid && ua.indexOf("Mobile") == -1;
var isMobileAndroidTypeS = isAndroid && ua.indexOf("Mobile") != -1;
var isAndroidChrome = isChrome && isAndroid;
var isAndroidFirefox = isFirefox && isAndroid;
var isMobileFirefox = isFirefox && ua.indexOf("Mobile") != -1;
var isTabletFirefox = isFirefox && ua.indexOf("Tablet") != -1;

//PC or Mobile
var isTablet = isMobileSafariTypeT || isMobileAndroidTypeT || isTabletFirefox;
var isSmartPhone =
	isMobileSafariTypeS || isMobileAndroidTypeS || isMobileFirefox;
var isMobile = isTablet || isSmartPhone || isAndroidChrome;
var isPC = !isMobile;

//Tablet
if (isTablet) {
	$("html").addClass("tablet");
}

//Mobile
if (isMobile) {
	$("html").addClass("mobile");
}

/* ------------------------------------------------------------
	Window Resized
* ------------------------------------------------------------ */
// ----------------------------------------
// ブラウザサイズにより切り替え
// ----------------------------------------
function checkViewWidth() {
	if (!common.viewWidth) common.viewWidth = {};
	var vw;
	if (window.matchMedia("(max-width: " + breakPoints.p + "px)").matches) {
		vw = "sp";
	}
	if (window.matchMedia("(min-width: " + (breakPoints.p + 1) + "px)").matches) {
		vw = "pc";
	}
	common.viewWidth = vw;
	var $html = $("html");
	if (common.viewWidth == "pc") {
		//PCだったら
		$html.removeClass("sp").addClass("pc");
	} else {
		//スマホだったら
		$html.removeClass("pc").addClass("sp");
	}
}
$(window).on("resize", checkViewWidth);

/* ------------------------------------------------------------
	画像など含めてページ読込み完了後に実行
* ------------------------------------------------------------ */
/* 実行
-------------------------------------------------- */
$(window).on({
	load() {
		setTimeout(function () {
			$('body').addClass('is-load');
			setTimeout(function () {
				common.pageScroll.init();
				common.pageScroll.sprit();
				common.cursor.init();
				setTimeout(function () {
					common.pageScroll.effect();
				}, 500);
			}, 300);
		}, 500);
		common.modal.init();
		common.style.vh();
		common.style.resize();
		common.menu.init();
		common.smooth.init();
		common.paramLink.init();
		common.scrollBar.init();
		common.scrollBar.resize();
	},
	scroll() {
		tsc = $(this).scrollTop();
		common.pageScroll.scroll();
	},
	resize() {
		common.style.resize();
		common.scrollBar.resize();
	}
});

/* ------------------------------------------------------------
	DOM構築後実行
* ------------------------------------------------------------ */
/* 実行
-------------------------------------------------- */
$(function () {
	if ($('[data-page="sub"]').length) {
		Cookies.set('in_access', 'value');
	} else {}
	checkViewWidth();
	$('html').addClass(common.uaBrowser.init());
	common.library.init();
	common.accordion.init();
	common.tel.init();
	common.sprit.init();
	common.headerNav.init();
	common.pageTop.init();
	common.fixed.init();
	common.youtube.init();
	common.modaal.init();
	common.search.init();
	common.tab.init();
	$(window).on({
		scroll() {
			if(common.viewWidth == 'pc'){
				$('.l-header__menu').css('left', -$(window).scrollLeft());
			} else {
				$('.l-header__menu').css({
					left: '',
				});
			}
		},
		resize() {
			common.menu.resize();
		}
	});
	setTimeout(function () {
		$('body').addClass('is-ready');
	}, 50);
	$(document).on({
		click() {
			common.scrollBar.init();
			common.scrollBar.resize();
		},
	}, '.js-modal-inline, .js-modal-img a, .js-modal-iframe');
});

common = {
	// ----------------------------------------
	// スタイル
	// ----------------------------------------
	style: {
		vh: function init() {
			const vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', vh + 'px');
		},
		resize: function resize() {
			$windowHeight = $(window).height();
			$headerHeight = $(".l-header").innerHeight();
			let vw = window.innerWidth;
			if (vw === window.innerWidth) {
			// 画面の横幅にサイズ変動がないので処理を終える
				return;
			}
			// 画面の横幅のサイズ変動があった時のみ高さを再計算する
			vw = window.innerWidth;
			vh();
		}
	},
	// ----------------------------------------
	// メニュー
	// ----------------------------------------
	menu : {
		init: function init() {
			this.__Flag_open = false;
			this.$header = $(".l-header__content");
			this.$menu = $(".l-header__menu");
			this.$button = $(".l-header__hamburger");
			$(document).on({
				click() {
					if (!common.menu.__Flag_open) {
						common.menu.open();
					} else {
						common.menu.close();
					}
				},
			}, '.l-header__hamburger');
			$(document).on({
				click() {
					common.menu.close();
				},
			}, '.l-header__menu a[href]');
			$('.l-header__menu-nav-item.js-accordion').each(function() {
				var $this = $(this);
				var $trigger = $this.find('.js-accordion__trigger');
				$trigger.append( '<span class="l-header__menu-nav-trigger-bars"><span class="l-header__menu-nav-trigger-bar"></span><span class="l-header__menu-nav-trigger-bar"></span></span>' );
			});
		},
		open: function open(flag) {
			const _this = this;
			_this.__Flag_open = true;
			this.$button.attr("aria-expanded", true);
			this.$menu.attr("aria-hidden", false);
			this.$menu.css({
				// paddingTop: $headerHeight
				// paddingBottom: $headerHeight,
			});
			$('.l-side__menu').each(function () {
				$('.l-header__menu-parent', this).append('<span class="l-header__menu-parent__trigger-bars"><span class="l-header__menu-parent__trigger-bar"></span><span class="l-header__menu-parent__trigger-bar"></span></span>');
			});
			$('html').addClass('is-open');
			if(common.viewWidth == 'pc'){
			} else {
				if (!common.fixed.__Flag_open) {
					common.scrollBarhide.init();
					common.fixed.open();
				}
			}
		},
		close: function close() {
			const _this = this;
			_this.__Flag_open = false;
			this.$button.attr("aria-expanded", false);
			this.$menu.attr("aria-hidden", true);
			this.$menu.css({
				paddingTop: ""
				//paddingBottom: "",
			});
			if (common.fixed.__Flag_open) {
				common.fixed.close();
			}
			if (common.accordion.__Flag_open) {
				common.accordion.close();
			}
			$('html').removeClass('is-open');
		},
		resize: function resize() {
			if (this.__Flag_open) {
				if(common.viewWidth == 'pc'){
					if (common.fixed.__Flag_open) {
						common.fixed.close();
					}
				} else {
					this.$menu.css({
						paddingTop: $headerHeight
						//paddingBottom: $headerHeight,
					});
					if (!common.fixed.__Flag_open) {
						common.scrollBarhide.init();
						common.fixed.open();
					}
				}
				
			} else {
			}
		}
	},
	// スムーススクロール
	smooth : {
		init: function init() {
			$('a[href*=\\#]:not([href=\\#])').not(".js-no-smooth, [class*='js-modal-'] ,a[href*='?id=tab']").click(function () {
				if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
					var target = $(this.hash);
					target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
					if (target.length) {
						$('html,body').animate({
							scrollTop: target.offset().top
						}, 500);
						return false;
					}
				}
			});
			if (locationHash != '') {
				setTimeout(function () {
					$('html,body').animate({
						scrollTop: $(locationHash).offset().top
					}, 200);
				}, 200)
			}
		}
	},
	// パラメーター付きリンク
	paramLink : {
		init: function init() {
			//URLを取得
			var url = location.href;
			url = url.split("#")[0];
			
			//?id=tabのとき
			if ( (url.indexOf('?id=tab') != -1)){
				var paramName=url.split("?")[1].split("=")[1].split("&")[0]; //?id=の後の文字を取得
				//クリックされたタブと同じ順番のコンテンツを表示
				$('#'+paramName).parents('.js-tabs').find('.js-tab__content').each(function() {
					$(this).attr('aria-selected',false);
				});
				var number=0;
				$('#'+paramName).parents('.js-tabs').find('.js-tab__trigger').each(function() {
					//console.log($(this).attr('id'));
					if($(this).attr('id')==paramName){
						$(this).attr('aria-selected',true);
						$('#'+paramName).parents('.js-tabs').find('.js-tab__content').eq(number).attr('aria-selected',true);
					}else{
						$(this).attr('aria-selected',false);
					}
					number++;
				});
			}

			//&anchorのとき
			if ( (url.indexOf('tab-anchor') != -1)){
				var paramName=url.split("tab-anchor")[1].split("=")[1]; //?id=の後の文字を取得
				var target = $('#' + paramName);
				if(target.length){
					$('html,body').animate({
						scrollTop: target.offset().top - $headerHeight
					}, 500);
				}
			}
		}
	},
	cursor : {
		init: function init() {
			if ($('#js-cursor').length) {
				var t = document.querySelector("#js-cursor"),
				n = t.querySelector(".js-cursor__circle"),
				r = {
					x: -100,
					y: -100
				},
				a = {
					x: 0,
					y: 0
				};
				window.addEventListener("mousemove", (function(e) {
					r.x = e.clientX, r.y = e.clientY
				}));
				var o = function() {
					var e = Math.round(r.x - a.x),
						o = Math.round(r.y - a.y);
					a.x += .15 * e, a.y += .15 * o;
					var i = function(e, t) {
							return 180 * Math.atan2(t, e) / Math.PI
						}(e, o),
						s = function(e, t) {
							var n = Math.sqrt(Math.pow(e, 2) + Math.pow(t, 2));
							return Math.min(n / 400, .3)
						}(e, o),
						l = "scale(" + (1 + s) + ", " + (1 - s) + ")",
						c = "rotate(" + i + "deg)",
						u = "translate3d(" + a.x + "px ," + a.y + "px, 0)";
					t.style.transform = u
				};
				requestAnimationFrame((function e() {
					o(), requestAnimationFrame(e)
				})), document.querySelectorAll("[data-cursor]").forEach((function(e) {
					e.addEventListener("mouseenter", (function() {
						var e = this.getAttribute("data-cursor");
						t.classList.add(e)
					})), e.addEventListener("mouseleave", (function() {
						var e = this.getAttribute("data-cursor");
						t.classList.remove(e)
					}))
				}))
			}
		}
	},
	headerNav : {
		init: function init() {
			$(window).on("scroll", function() {
				if ($(this).scrollTop() > 100) {
					$('.l-header__content').attr('data-scroll', 'true');
					$('.l-header__content').addClass('is-down');
				} else {
					$('.l-header__content').attr('data-scroll', 'false');
					$('.l-header__content').removeClass('is-down');
				}
			});
		}
	},
	pageTop : {
		init: function init() {
			$(window).on("scroll", function() {
				if ($(this).scrollTop() > 100) {
					$('.l-footer__page-top').addClass('is-scroll-down');
				} else {
					$('.l-footer__page-top').removeClass('is-scroll-down');
				}
			});
			if(common.viewWidth == 'pc'){
				//PCだったら
				$('.l-footer__inner').waypoint(function(direction) {
					var activePoint = $(this.element);
					if (direction === 'down') {
						$('.l-footer__page-top').addClass('is-scroll-finish');
					} else {}
					if (direction === 'up') {
						$('.l-footer__page-top').removeClass('is-scroll-finish');
					} else {}
				}, { offset: '100%' + 40 + 'px' });
			} else {
				//スマホだったら
				$('.l-footer__inner').waypoint(function(direction) {
					var activePoint = $(this.element);
					if (direction === 'down') {
						$('.l-footer__page-top').addClass('is-scroll-finish');
					} else {}
					if (direction === 'up') {
						$('.l-footer__page-top').removeClass('is-scroll-finish');
					} else {}
				}, { offset: '100%' + 20 + 'px' });
			}
			$('.l-footer__page-top').on('click', function () {
				$('body,html').animate({scrollTop:0}, 800, 'swing');
				return false;
			})
		}
	},
	// ページスクロール
	pageScroll : {
		effect: function init() {
			var boxes = document.querySelectorAll('[data-scroll="effect"]');
			var boxesArray = Array.prototype.slice.call(boxes, 0);
			var options = {
				root: null, // 今回はビューポートをルート要素とする
				rootMargin: "-10% 0px", // ビューポートの中心を判定基準にする
				threshold: 0, // 閾値は0
			};
			var observer = new IntersectionObserver(doWhenIntersect, options);
			boxesArray.forEach(function (box) {
				observer.observe(box);
			});
			function doWhenIntersect(entries) {
				var entriesArray = Array.prototype.slice.call(entries, 0);
				entriesArray.forEach(function (entry) {
					if (entry.isIntersecting) {
						entry.target.classList.add("is-active");
					}
				});
			}
		},
		sprit: function init() {
			var boxes = document.querySelectorAll('[data-scroll="effect-sprit"]');
			var boxesArray = Array.prototype.slice.call(boxes, 0);
			var options = {
				root: null, // 今回はビューポートをルート要素とする
				rootMargin: "0% 0% -20% 0%", // ビューポートの中心を判定基準にする
				threshold: 0, // 閾値は0
			};
			var observer = new IntersectionObserver(doWhenIntersect, options);
			boxesArray.forEach(function (box) {
				observer.observe(box);
			});
			function doWhenIntersect(entries) {
				var entriesArray = Array.prototype.slice.call(entries, 0);
				entriesArray.forEach(function (entry) {
					if (entry.isIntersecting) {
						entry.target.classList.add("is-active");
					}
				});
			}
		},
		init: function init() {
			var options = {
				root: null, // 今回はビューポートをルート要素とする
				rootMargin: "0% 0% -20% 0%", // ビューポートの中心を判定基準にする
				threshold: 0, // 閾値は0
			};
			var $slickSlider,
				slickSliders = document.querySelectorAll('[data-autoplay="false"]'),
				slickSlidersArr = Array.prototype.slice.call(slickSliders);
			if ('IntersectionObserver' in window) {
				var onChange = function onChange(elements, observer) {
					elements.forEach(function (element) {
						if (element.isIntersecting) {
							//console.log("element intersecting", element.target);
							var config = {};
							loadSlick(element.target, config);
							observer.unobserve(element.target);
						}
					});
				};
				var observer = new IntersectionObserver(onChange, options);
				slickSlidersArr.forEach(function (slider) {
					return observer.observe(slider);
				});
			} else {
				//IntersectionObserver NOT Supported
				slickSlidersArr.forEach(function (slickSlider) {
					return loadSlick(slickSlider);
				});
			}
			function loadSlick(slickSlider, config) {
				$slickSlider = $(slickSlider);
				if ($(slickSlider).find('.slick-track').length) {
					$slickSlider.slick("slickPlay");
				}
			}
		},
		resize() {
		},
		scroll: function (y) {
		}
	},
	// ブラウザ判別
	uaBrowser : {
		init: function init() {
			var userAgent = window.navigator.userAgent.toLowerCase();
			var appVersion = window.navigator.appVersion.toLowerCase();
			if (userAgent.indexOf('msie') > -1) {
				if (appVersion.indexOf('msie 7.0') > -1) {
					return 'ie ieold';
				} else if (appVersion.indexOf('msie 8.0') > -1) {
					return 'ie ieold';
				} else if (appVersion.indexOf('msie 9.0') > -1) {
					return 'ie ie9';
				} else if (appVersion.indexOf('msie 10.0') > -1) {
					return 'ie ie10';
				} else {
					return 'ie ieold';
				}
			}
			else if (appVersion.indexOf('trident/7.0') > -1) {
				return 'ie ie11';
			}
			else if (userAgent.indexOf('edge') > -1) {
				return 'edge';
			}
			else if (userAgent.indexOf('firefox') > -1) {
				return 'firefox';
			} else if (userAgent.indexOf('opera') > -1) {
				return 'opera';
			} else if (userAgent.indexOf('chrome') > -1) {
				return 'chrome';
			} else if (userAgent.indexOf('safari') > -1) {
				return 'safari';
			}
		}
	},
	// ライブラリ
	library : {
		init: function init() {
			let elements = $(".js-sticky");
			Stickyfill.add(elements);
			objectFitImages(); //https://github.com/fregante/object-fit-images
			$(".js-matchHeight").matchHeight();
			$(".js-matchHeight-all").each(function() {
				$(this).children('*').matchHeight({
					byRow: false,
				});
			});
		}
	},
	// アコーディオン
	accordion : {
		init: function init() {
			const _this = this;
			$('.js-accordion__trigger').not('.js-accordion-one .js-accordion__trigger').on('click', function () {
				var $isClosed = $(this).closest('.js-accordion').toggleClass('is-active').hasClass('is-active');
				if ($isClosed) {
					_this.__Flag_open = true;
					gsap.to($(this).next('.js-accordion__content'),{
						height:'auto',
						duration: 0.5,
						ease: "power2.inOut"
					});
					$(this).attr("aria-expanded", true);
					$(this).next('.js-accordion__content').attr("aria-hidden", false);
				} else {
					_this.__Flag_open = false;
					gsap.to($(this).next('.js-accordion__content'),{
						height: 0,
						duration: 0.5,
						ease: "power2.inOut"
					});
					$(this).attr("aria-expanded", false);
					$(this).next('.js-accordion__content').attr("aria-hidden", true);
				}
			});
			$('.c-accordion').each(function() {
				var $this = $(this);
				var $trigger = $this.find('.c-accordion__trigger');
				$trigger.append( '<span class="c-accordion__trigger-bars"><span class="c-accordion__trigger-bar"></span><span class="c-accordion__trigger-bar"></span></span>' );
			});
		},
		open: function open() {
			const _this = this;
			_this.__Flag_open = true;
			$('.js-accordion.is-active').children('.js-accordion__trigger').attr("aria-expanded", true);
			$('.js-accordion.is-active').children('.js-accordion__content').attr("aria-hidden", false);
			gsap.to($('.js-accordion.is-active').children('.js-accordion__content'),{
				height:'auto',
				duration: 0.5,
				ease: "power2.inOut"
			});
		},
		close: function close() {
			const _this = this;
			_this.__Flag_open = false;
			$('.js-accordion').removeClass('is-active')
			$('.js-accordion').children('.js-accordion__trigger').attr("aria-expanded", false);
			$('.js-accordion').children('.js-accordion__content').attr("aria-hidden", true);
			gsap.to($('.js-accordion').children('.js-accordion__content'),{
				height: 0,
				duration: 0,
				ease: "power2.inOut"
			});
		}
	},
	// 電話番号
	tel : {
		init: function init() {
			if(common.viewWidth == 'sp'){
				$('.js-tel-link').each(function() {
					var str = $(this).html();
					if ($(this).children().is('img')) {
					$(this).html($('<a>').attr('href', 'tel:' + $(this).children().attr('alt').replace(/-/g, '')).append(str + '</a>'));
						} else {
					$(this).html($('<a>').attr('href', 'tel:' + $(this).text().replace(/-/g, '')).append(str + '</a>'));
					}
				});
			}
		}
	},
	// 文字分解
	sprit : {
		init: function init() {
			$(".js-split-word").each(function (index) {
				var $this = $(this);
				// 子ノード3つそれぞれに対して処理
				$this.contents().each(function (_, node) {
					// 子ノードを一旦DOMツリーから除去
					// 変数`node`はjQueryオブジェクトではないので`node.remove();`とは書けません。
					node.parentNode.removeChild(node);
					// ノードの種類によって条件分岐
					switch (node.nodeType) {
					// テキストノードなら質問文にある処理を適用
					case Node.TEXT_NODE:
						// テキストノードの文字列を取得
						var text_split = node.textContent.split("");
						function animate() {
							text_split.forEach(function (val) {
								let regexp =  /[\p{C}\p{Z}]/gu;
								let result = val.match(regexp);
								if(result!==null){
									$this.append(result[0]);
								}else{
									$this.append("<span class='o-effect-sprit-word'>" + val + "</span>");
									$this.addClass("o-effect-sprit-word-wrap");
								}
							});
						}
						animate();
						break;
					// テキストノード以外(HTML要素)ならappendし直す
					default:
						$this.append(node);
					}
				});
				$this.find('.o-effect-sprit-word').each(function (index) {
					$(this).css({
						transitionDelay: index * 0.03 + "s"
					});
				});
			});
			$("[data-transition-delay]").each(function (i, e) {
				var delay = $(e).data("transition-delay");
				$(e).children().each(function (index) {
					$(this).css({ transitionDelay: delay + index * 0.15 + "s" });
				});
			});
		}
	},
	// スクロールバー
	scrollBar : {
		init: function init() {
			const s = $('.js-scrollbar');
			for (let e = 0; e < s.length; e++)
			new SimpleBar(s[e], {
				autoHide: !1,
				scrollbarMinSize: 10
			});
			var $elems = $('.js-scrollbar');
			$elems.each(function () {
				var $this = $(this);
				var $elem = $this.find('.simplebar-content-wrapper');
				$elem.scroll(function() {
					var init = $(this).scrollLeft();
					$this.addClass('is-scrolled');
				})
			});
		},
		resize: function resize() {
			var $elems = $('.js-scrollbar');
			$elems.each(function () {
				var $this = $(this);
				var $elem = $this.find('.simplebar-content-wrapper');
				var vertical = $elem.get(0).scrollHeight > $elem.get(0).clientHeight;
				var horizontal = $elem.get(0).scrollWidth > $elem.get(0).clientWidth;
				if (horizontal) {
					$this.attr('data-scroll-x', 'true');
				} else {
					$this.attr('data-scroll-x', 'false');
				}
				if (vertical) {
					$this.attr('data-scroll-y', 'true');
				} else {
					$this.attr('data-scroll-y', 'false');
				}
			});
		}
	},
	// 背景固定
	fixed : {
		init: function init() {
			this.__Flag_open = false;
		},
		open: function open() {
			const _this = this;
			_this.__Flag_open = true;
			sc = $(window).scrollTop();
			$('body').css({
				width: '100%',
				height: 'auto',
				position: 'fixed',
				// top: -sc,
				overflow: 'hidden'
			});
		},
		close: function close() {
			const _this = this;
			_this.__Flag_open = false;
			$('body').attr({
				style: ''
			});
			$('html, body').prop({
				scrollTop: sc
			});
		}
	},
	//スクロールバーの出現によるガタつきを防ぐ
	scrollBarhide : {
		init: function init() {
			const body = document.getElementsByTagName('body')[0];
			const scrollBarwidth = window.innerWidth - document.body.clientWidth;
			body.style.paddingRight = scrollBarwidth + 'px';
		}
	},
	// モーダル
	modal : {
		init: function init() {
			$('.js-modal-inline').on('click', function () {
				$('body').magnificPopup({
					delegate: '.js-modal-inline',
					type: 'inline',
					mainClass: 'mfp-fade',
					closeMarkup: '<button class="c-modal__close-button js-modal-close" type="button"><span class="c-modal__close-button-inner"><span class="c-modal__close-button-bars"><span class="c-modal__close-button-bar"></span><span class="c-modal__close-button-bar"></span></span></span></button>',
					callbacks: {
						open: function () {
							$('.mfp-wrap').css({
								position: 'fixed',
								top: 0
							});
							$('.js-modal-close').on('click', function (e) {
								e.preventDefault();
								$.magnificPopup.close();
							});
							if (!common.fixed.__Flag_open) {
								common.scrollBarhide.init();
								common.fixed.open();
							}
						},
						close: function () {
							$('.mfp-wrap').attr({
								style: ''
							});
							if (common.fixed.__Flag_open) {
								common.fixed.close();
							}
						},
					}
				});
			});
			$('.js-modal-img').each(function () {
				$(this).magnificPopup({
					delegate: 'a',
					type: 'image',
					mainClass: 'mfp-fade',
					image: {
						markup:
							'<button class="c-modal__close-button --cirle js-modal-close" type="button"><span class="c-modal__close-button-inner"><span class="c-modal__close-button-bars"><span class="c-modal__close-button-bar"></span><span class="c-modal__close-button-bar"></span></span></span></button>' +
							'<div class="mfp-with-anim">' +
							'<div class="mfp-counter"></div>' +
							'<div class="mfp-inner">' +
							'<div class="mfp-figure-wrap">' +
							'<div class="mfp-figure">' +
							'<div class="mfp-img"></div>' +
							'<div class="mfp-bottom-bar">' +
							'<div class="mfp-title"></div>' +
							'</div>' +
							'</div>' +
							'</div>' +
							'</div>' +
							'</div>',
					},
					gallery: {
						enabled: true,
						tCounter: '<span class="current">%curr%</span><span class="line"></span><span class="total">%total%</span>',
						arrowMarkup: '<button class="c-modal__arrow c-modal__arrow-%dir% js-modal__arrow" type="button"><i class="c-icon-arrow-%dir% mfp-prevent-close" aria-hidden="true"></i></button>',
					},
					closeMarkup: '<button class="c-modal__close-button --cirle js-modal-close" type="button"><span class="c-modal__close-button-inner"><span class="c-modal__close-button-bars"><span class="c-modal__close-button-bar"></span><span class="c-modal__close-button-bar"></span></span></span></button>',
					callbacks: {
						open: function () {
							$('.mfp-wrap').css({
								position: 'fixed',
								top: 0
							});
							$('.js-modal-close').on('click', function (e) {
								e.preventDefault();
								$.magnificPopup.close();
							});
							if (!common.fixed.__Flag_open) {
								common.scrollBarhide.init();
								common.fixed.open();
							}
						},
						close: function () {
							$('.mfp-wrap').attr({
								style: ''
							});
							if (common.fixed.__Flag_open) {
								common.fixed.close();
							}
						},
					}
				});
			});
			$('.js-modal-iframe').each(function () {
				$(this).magnificPopup({
					type: 'iframe',
					mainClass: 'mfp-fade',
					iframe: {
						markup: '<button class="c-modal__close-button --cirle js-modal-close" type="button"><span class="c-modal__close-button-inner"><span class="c-modal__close-button-bars"><span class="c-modal__close-button-bar"></span><span class="c-modal__close-button-bar"></span></span></span></button>' +
							'<div class="mfp-with-anim">' +
							'<div class="o-wrapper--thin o-wrapper-sp-fluid">' +
							'<div class="mfp-iframe-scaler-wrap">' +
							'<div class="mfp-iframe-scaler">' +
							'<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
							'</div>' +
							'</div>' +
							'</div>' +
							'</div>',
						patterns: {
							youtube: {
								index: 'youtube.com/',
								id: function (url) { return url },
								src: '%id%'
							}
						}
					},
					closeMarkup: '<button class="c-modal__close-button js-modal-close" type="button"><span class="c-modal__close-button-inner"><span class="c-modal__close-button-bars"><span class="c-modal__close-button-bar"></span><span class="c-modal__close-button-bar"></span></span></span></button>',
					callbacks: {
						open: function () {
							$('.mfp-wrap').css({
								position: 'fixed',
								top: 0
							});
							$('.js-modal-close').on('click', function (e) {
								e.preventDefault();
								$.magnificPopup.close();
							});
							if (!common.fixed.__Flag_open) {
								common.scrollBarhide.init();
								common.fixed.open();
							}
						},
						close: function () {
							$('.mfp-wrap').attr({
								style: ''
							});
							if (common.fixed.__Flag_open) {
								common.fixed.close();
							}
						},
					}
				});
			});
			//閉じる処理
			$('.mfp-close').on('click', function (e) {
				e.preventDefault();
				$.magnificPopup.close();
			});
			//モーダル外のページ内リンクに飛びたい場合、その位置に移動
			$('.js-modal-outer-link').on('click', function () {
				var href = $(this).attr('href');
				var target = $(href == '#' || href == '' ? 'html' : href);
				if (common.viewWidth == 'pc') {
					//PCだったら
					$('html,body').animate({
						scrollTop: target.offset().top
					}, 0);
				} else {
					//スマホだったら
					$('html,body').animate({
						scrollTop: target.offset().top - $headerHeight
					}, 0);
				}
			});
			//モーダル内のアンカーリンクの処理
			$('.js-modal-inner-link').on('click', function () {
				var href = $(this).attr('href');
				var target = $(href == '#' || href == '' ? 'html' : href);
				var position = target.offset().top;
				var mado = $('.c-modal').offset().top; //これ大事
				$('.c-modal-inner').animate({
					scrollTop: position + $('.c-modal-inner').scrollTop() - mado
				}, 500);
			});
		}
	},
	youtube : {
		init: function init() {
			$(".p-works__mv-youtube-wrap").click(function(){
				$(".p-works__mv-youtube-content").fadeIn(300);
				$(".p-works__mv-youtube-content .p-works__mv-youtube-inner").each(function(){
					var videohi = $(this).width() / 100 * 56;
					$(this).css("height",videohi);
				});
			});
			$(".p-works__mv-youtube-content .p-works__mv-youtube-btn").click(function(){
				$(".p-works__mv-youtube-content").fadeOut(300);
				videoControl("pauseVideo");
				function videoControl(action) {
				  var $playerWindow = $(".p-works__mv-youtube-content iframe")[0].contentWindow;
				  $playerWindow.postMessage(
					'{"event":"command","func":"' + action + '","args":""}',
					"*"
				  );
				}
			});
			$(".p-works__mv-youtube-content").click(function(){
				$(".p-works__mv-youtube-content").fadeOut(300);
				videoControl("pauseVideo");
				function videoControl(action) {
				  var $playerWindow = $(".p-works__mv-youtube-content iframe")[0].contentWindow;
				  $playerWindow.postMessage(
					'{"event":"command","func":"' + action + '","args":""}',
					"*"
				  );
				}
			});
		}
	},
	modaal : {
		init: function init() {
			if ($(".js-modal-target").length > 0) {
				$(".js-modal-target").modaal();
			}
			if ($(".js-modal-button-target").length > 0) {
				var target = $(".js-modal-button-target").data('modal');
				$(".js-modal-button-target").modaal({
					content_source: target
				});
			}
			$('.js-modal-close').on('click', function () {
				// モーダルを閉じる
				var target = $('.js-modal-button-target');
				target.modaal('close');
			})
		}
	},
	search : {
		init: function init() {
			$(function(){
				///////////////////////////////////////////////
				//	雇用形態
				///////////////////////////////////////////////
				var $emp_all = $("#employment_all");
				var $emp_c = $(".employment");
				var $emp_id = $("#employment");
				
				// 「こだわらない」にチェック
				$emp_all.change(function(){
					if($(this).is(":checked")){
						$emp_c.prop('checked', true);
						$emp_c.parent().addClass("check_on");
					} else{
						$emp_c.prop('checked', false);
						$emp_c.parent().removeClass("check_on");
					}
				});
				
				// 「雇用形態」のいずれかのチェックが外されたら
				$emp_c.change(function(){
					if($(this).is(":checked")){
						if($emp_id.find(":checked").length == $emp_id.find("li").length - 1){ //すべてチェックされたら 
							$emp_all.prop('checked', true);
							$emp_all.parent().addClass("check_on");
						}
					} else{
						$emp_all.prop('checked', false);
						$emp_all.parent().removeClass("check_on");
					}
				});
				
				///////////////////////////////////////////////
				//	希望給与
				///////////////////////////////////////////////
				var $sgmt = $("#pay_segment");
				var $no_amt = $("#nothing_amount");
				var $ph_amt = $("#pay_hour_amount");
				var $s_amt = $("#salary_amount");
				var $i_amt = $("#income_amount");
				
				// セレクトボックスの選択された name を取得
				var $sel_name = $("#pay_segment option:selected").attr("name");
				
				//デフォルト
				if($sel_name === "nothing"){
					$no_amt.show();
					$ph_amt.hide();
					$s_amt.hide();
					$i_amt.hide();
				} else if($sel_name === "pay_hour"){
					$no_amt.hide();
					$ph_amt.show();
					$s_amt.hide();
					$i_amt.hide();
				} else if($sel_name === "salary"){
					$no_amt.hide();
					$ph_amt.hide();
					$s_amt.show();
					$i_amt.hide();
				} else if($sel_name === "income"){
					$no_amt.hide();
					$ph_amt.hide();
					$s_amt.hide();
					$i_amt.show();
				}
				
				$sgmt.change(function(){
					// セレクトボックスの選択された name を取得
					var $sel_name = $("#pay_segment option:selected").attr("name");
					// hiddenにセット
					$("#sel_group_name").val($sel_name);
					
					if($sel_name === "nothing"){
						$no_amt.show();
						$ph_amt.hide();
						$s_amt.hide();
						$i_amt.hide();
					} else if($sel_name === "pay_hour"){
						$no_amt.hide();
						$ph_amt.show();
						$s_amt.hide();
						$i_amt.hide();
					} else if($sel_name === "salary"){
						$no_amt.hide();
						$ph_amt.hide();
						$s_amt.show();
						$i_amt.hide();
					} else if($sel_name === "income"){
						$no_amt.hide();
						$ph_amt.hide();
						$s_amt.hide();
						$i_amt.show();
					}
				});
			});
		}
	},
	// ----------------------------------------
	// タブ
	// ----------------------------------------
	tab: {
		init: function init() {
			$(document).on('click', '.js-tab__trigger', function() {
				console.log("a");
				var $this = $(this),
					$tab = $this.parents('.js-tabs'),
					$tab_trigger = $tab.find('.js-tab__trigger'),
					$tab_content = $tab.find('.js-tab__content');
				$tab_trigger.attr("data-link-current", false);
				$this.attr("data-link-current", true);
				var $thisIndex = $tab_trigger.index(this);
				$tab_content.attr("data-link-current", false);
				$tab_content.eq($thisIndex).attr("data-link-current", true);
			});
		}
	}
}